.contact-info-register {
  font-family: "Noto Kufi Arabic", sans-serif;
}
.contact-info-register form .contact-info-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  margin-top: 4rem;
  margin-bottom: 4rem;
}
.contact-info-register form .contact-info-form .form-sec {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.2rem;
}
.contact-info-register form .contact-info-form .form-sec label {
  font-size: 20px;
  font-weight: 500;
  color: var(--primaryColor);
}
.contact-info-register form .contact-info-form .form-sec input {
  padding: 0.7rem;
  border: 1px solid #c2c2c2;
  width: 100%;
  border-radius: 4px;
}
.million-contact-info-form .PhoneInputInput {
  background-color: black;
  color: white;
}
.tak-contact-info-form .PhoneInputInput {
  background-color: rgba(31, 31, 31, 1);
  color: white;
}
.athar-contact-info-form .PhoneInputInput {
  background-color: transparent;
  color: black;
  border: 1px solid #f15a3a !important;
}
.tak-contact-info-form .PhoneInputCountrySelect {
  border: 1px solid rgb(255, 5, 147);
  background-color: black;
  color: white;
}
.trans-contact-info-form .PhoneInputCountrySelect {
  border: 1px solid rgb(181, 89, 232);
  background-color: rgb(31, 31, 31);
  color: white;
}
.shark-contact-info-form .PhoneInputCountrySelect {
  border: 1px solid #02c6c6;
  background-color: rgba(31, 31, 31, 1);
  color: white;
}
.athar-contact-info-form .PhoneInputCountrySelect {
  border: 1px solid #f15a3a;
  background-color: transparent;
  color: black;
}
.contact-info-register form .contact-info-form .form-sec input::placeholder {
  font-size: 16px;
  font-weight: 300;
}
.register-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  width: 100%;
  padding-bottom: 3rem;
}
.register-btns .btn-next,
.register-btns .btn-back {
  background-color: var(--primaryColor);
  color: white;
  width: 100%;
  padding: 0.3rem;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  margin: auto;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  border-radius: 3px;
}
.register-btns .btn-back {
  background-color: white;
  color: var(--primaryColor);
  width: 100%;
  padding: 0.3rem;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  margin: auto;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  border: 1px solid;
}
.contact-info-register .PhoneInputCountrySelect {
  padding: 0.34rem;
  width: 10vw;
  border: 1px solid #cac8c8;
  font-size: 16px;
  font-weight: 300;
  color: #ababab;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.million-contact-info-form .PhoneInputCountrySelect {
  color: white;
  background-color: black;
}
.trans-contact-info-form .PhoneInputCountrySelect {
  border: 1px solid #02c6c6;
  background-color: rgba(31, 31, 31, 1);
  color: white;
}
.shark-contact-info-form .PhoneInputCountrySelect {
  border: 1px solid rgb(181, 89, 232);
  background-color: rgb(31, 31, 31);
  color: white;
}
.athar-contact-info-form .PhoneInputCountrySelect {
  border: 1px solid #f15a3a;
  background-color: transparent;
  color: black;
}
.tak-contact-info-form .PhoneInputCountrySelect {
  color: white;
  background-color: rgba(31, 31, 31, 1);
  border: 1px solid rgb(255, 5, 147);
}
.fadaa-contact-info-form .PhoneInputCountrySelect{
  border: 1px solid rgb(116, 68, 189) ;
  background-color: black;
  color:white;
}
#toogle-direction {
  direction: ltr;
}

@media only screen and (max-width: 992px) {
  .contact-info-register form .contact-info-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
  }
  .contact-info-register form .PhoneInput {
    justify-content: flex-end;
    width: 100%;
  }
  .contact-info-register .PhoneInputCountrySelect {
    width: 25vw;
  }
  .contact-info-register .register-btns {
    display: flex;
    flex-direction: column;
    gap: 0;
  }
  .contact-info-register .register-btns .btn-next,
  .contact-info-register .register-btns .btn-back {
    font-size: 15px;
    width: 100%;
  }
  .contact-info-register .register-btns .btn-next {
    margin-bottom: 0;
  }
  .contact-info-register .register-btns .btn-back {
    margin-top: 1rem;
  }
  .contact-info-register form .contact-info-form .form-sec {
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .contact-info-register form .contact-info-form .form-sec input {
    width: 100%;
  }
  .contact-info-register form .contact-info-form .form-sec label {
    font-size: 15px;
  }
  .contact-info-register .PhoneInputCountrySelect {
    padding: 0.3rem;
  }
  .contact-info-register form .contact-info-form {
    gap: 2rem;
  }
}
