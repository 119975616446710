.Change-password-setting form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  max-width: 545px;
  margin: auto;
}
.Change-password-setting form div,
.Change-password-setting form div input {
  width: 100%;
}
.Change-password-setting form div label {
  /* color: #022444; */
  color: var(--primaryColor);;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10px;
}
.Change-password-setting form div input {
  border: none;
  border: 1px solid #e6e6e6;
  padding: 10px;
  border-radius: 5px;
}
.Change-password-setting form div input:focus {
  outline: none;
}
.Change-password-setting form div input::placeholder {
  color: #c2c1c1;
}
.Change-password-setting form button.btn,
.Change-password-setting form button.btn:hover {
  background-color: var(--primaryColor);
  color: #fff;
  width: 100%;
  border-radius: 0;
}

@media (max-width: 767px) {
  .profile-settin-page h1 {
    font-size: 36px;
    margin-top: 12rem;
  }
  .Change-password-setting form div label {
    font-size: 16px;
  }
}
