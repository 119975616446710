a {
  text-decoration: none;
}

.login-choose-comp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 5rem;
}
.login-choose-comp .login-choose-title {
  display: grid;
}
.login-choose-comp .login-choose-title h3 {
  font-size: 31px;
  font-weight: 700;
  color: var(--primaryColor);
}

.login-choose-comp .login-choose-title h6 {
  font-size: 23px;
  font-weight: 300;
  line-height: 48.58px;
  width: 90%;
  margin: auto;
  color: var(--loginFormTitleColor);
}
.login-choose-comp .login-choice {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.login-choose-comp .login-choice .influencer {
  padding: 3rem;
  background-color: var(--primaryColor);
  color: white;
  font-size: 31px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 5px;
  width: 202px;
  height: 182px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-choose-comp .login-choice .influencer:hover,
.login-choose-comp .login-choice .trade-mark:hover {
  background-color: white;
  color: var(--primaryColor);
  border: 1px solid var(--primaryColor);
}
.login-choose-comp .login-choice .trade-mark {
  padding: 3rem;
  background-color: var(--primaryColor);
  color: white;
  font-size: 31px;
  font-weight: 800;
  cursor: pointer;
  border-radius: 5px;
  width: 202px;
  height: 182px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 992px) {
  .login-choose-comp .login-choose-title h3 {
    font-size: 19px;
  }
  .login-choose-comp .login-choose-title h6 {
    font-size: 18px;
    width: 75%;
  }
  .login-choose-comp .login-choice .influencer {
    padding: 3rem;
    font-size: 20px;
  }
  .login-choose-comp .login-choice .trade-mark {
    padding: 3rem 1.2rem;
    font-size: 20px;
  }
  .login-choose-comp .login-choice {
    gap: 2rem;
    margin-bottom: 6rem;
  }
  .login-choose-comp .login-choice .influencer,
  .login-choose-comp .login-choice .trade-mark {
    width: 165.19px;
    height: 148.83px;
  }
  .login-choose-comp {
    margin-top: 5rem;
  }
}
@media only screen and (max-width: 360px) {
  .login-choose-comp .login-choice .influencer,
  .login-choose-comp .login-choice .trade-mark {
    width: 135.19px;
    height: 118.83px;
  }
  .login-choose-comp .login-choice {
    gap: 0.2rem;
  }
}
