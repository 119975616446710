.infu-footer{
   background: url(../../assets//images/infu-footer-back.png);
   background-size: cover;
   color: white;
   padding-top: 6rem;
   padding-bottom: 1rem;

}
.foot-head{
    display: flex;
    gap: 8rem;
    align-items: baseline;
}
.infu-links{
    display: flex;
    gap: 3rem;

}
.infu-links h3{
    font-size: 22px;
    cursor: pointer;
}
.infu-links h4{
    font-size: 18px;
}
.infu-conact img{
    margin-left: .8rem;
}
.infu-social-links{
    display: flex;
    gap: 1.2rem;
    margin-right: 2rem;
}
.infu-social-links img{
    cursor: pointer;
}
.infu-conact{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.infu-footer .privcy{
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
 
}
.infu-footer .privcy h5{
    font-size: 11px;
}

@media (max-width: 992px) {

    .foot-head {
        flex-direction: column;
        gap: 2rem;
        align-items: flex-start;
    }
    .infu-links{
        flex-direction: column;
        gap: 3rem;
        margin-right: 1rem;
    }
    .privcy{
        flex-direction: column;
        
        text-align: center;
    }
    .infu-social-links {
        justify-content: center;
        margin-right: 0;
        margin: 3rem 0 2rem 0;
    }
    .infu-logo{
        width: 100%;
        text-align: center;
        margin-bottom: 2rem;

    }
    .infu-logo img{
        width: 50%;
       
    }
}