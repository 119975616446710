.qr-page-parent {
  padding: 9rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  min-height: 95vh;
}
.qr-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.qr-page .right-check-parent {
  width: 60%;
}
.qr-page .right-check-parent .right-check-child {
  width: 100%;
}
.qr-page .qr-titles {
  display: grid;
  gap: 1rem;
  text-align: center;
}
.qr-page .qr-titles h3 {
  font-size: 45px;
  font-weight: 700;
  line-height: 63px;
}
.qr-page .qr-titles p {
  font-size: 23px;
  font-weight: 300;
  line-height: 50px;
  margin: 0;
}
.qr-page .btn-parent-more {
  text-align: center;
  margin-top: 3rem;
}
.qr-page .btn-parent-more .btn-show-more {
  background-color: var(--primaryColor);
  color: white;
  border-radius: 3px;
}
@media (max-width: 992px) {
  .qr-page {
    margin-top: 4rem;
  }
  .qr-page .qr-titles h3 {
    font-size: 25px;
    line-height: 45px;
  }
}
@media (max-width: 768px) {
  .qr-page .qr-titles h3 {
    font-size: 18px;
    line-height: 45px;
  }
  .qr-page .qr-titles p {
    font-size: 19px;
    line-height: 43px;
  }
}
