.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  z-index: 9999999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popup {
  background-color: #fff;
  border-radius: 7px;

  padding: 3rem;
}
.popup .warning-icon {
  width: 40%;
  margin: auto;
}
.popup .warning-icon img {
  width: 100%;
}
.popup h2 {
  color: var(--primaryColor);
  font-size: 45px;
  font-weight: 700;
  margin: 3rem auto 1rem;
  text-align: center;
}
.popup h5 {
  color: var(--primaryColor);
  font-size: 24px;
  text-align: center;
  margin-bottom: 5rem;
}
.popup .actions-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.popup .actions-btn .btn {
  font-size: 20px;
  padding: 10px 80px;
  border: 1px solid #022444;
  border-radius: 2px;
  transition: all 0.2s;
}
.popup .actions-btn .btn-yes {
  background-color: var(--primaryColor);
  color: #fff;
}

@media (max-width: 991px) {
  .popup {
    padding: 2rem;
    max-width: 25rem;
  }
  .popup .warning-icon {
    width: 30%;
  }
  .popup h2 {
    font-size: 30px;
    margin: 2rem auto 1rem;
  }
  .popup h5 {
    font-size: 18px;
    margin-bottom: 3.5rem;
  }
  .popup .actions-btn .btn {
    font-size: 14px;
    padding: 5px 70px;
  }
}
@media (max-width: 500px) {
  .popup {
    max-width: 20rem;
  }
  .popup .actions-btn .btn {
    font-size: 14px;
    padding: 5px 50px;
  }
}
