.bar p {
  font-size: 20px !important;
}
.bar .background {
  width: 100%;
  background-color: #f0f9ff;
  height: 8px;
  border-radius: 24px;
  position: relative;
}
.bar .fill {
  position: absolute;
  top: 0;
  left: 0;
  height: 8px;
  border-radius: 24px;
  transition: 0.5s;
}
@media (max-width: 576px) {
  .bar p {
    font-size: 15px !important;
  }
}
