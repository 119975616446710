.influencers-logs-parent {
  padding: 12rem 0 0 0;
  min-height: 95vh;
}
.influencers-logs-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: auto;
}
.influencers-logs-title h1 {
  font-size: 45px;
  font-weight: 800;
  line-height: 63px;
  color: var(--primaryColor);
}
.influencers-logs-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}
.influencers-logs-filter .influencers-filter-sec1 {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 18px;
  font-weight: 600;
  color: #7c7e81c2;
}
.influencers-logs-filter .influencers-filter-sec2 {
  color: var(--primaryColor);
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}

.logs {
  /* box-shadow: 1px 16px 30px 5px #03022912; */
  padding: 48px 104px;
  border-radius: 32px;
  margin-top: 3rem;
  /* margin-left: 5rem; */

  width: 90%;
  margin: auto;
}
.logs h2 {
  margin-bottom: 3rem;
}
.logs-head {
  display: flex;
  gap: 1rem;
}
.logs-content {
  border-right: 3px solid #d3d0d0;
  position: relative;
}
.border-logs {
  display: flex;
  justify-content: space-between;
  padding-right: 3rem;
  margin-bottom: 1.5rem;
  position: relative;
}
.oval {
  position: absolute;
  left: -0.8rem;
  top: 0.5rem;
}
.logs-time p {
  background-color: rgba(255, 238, 242, 1);
  border-radius: 16px;
  padding: 0.6rem 0.8rem;
  color: #fe5578;
  text-align: center;
}
.logs-head img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.line-break-logs {
  width: 97%;
  background-color: rgba(208, 208, 208, 1);
  height: 1px;
  margin: auto;
  margin-bottom: 2rem;
}
.line-break-logs:nth-last-of-type() {
  display: none;
}
.circle-logs {
  position: absolute;
  right: -15px;
  top: 0;
  background-color: white;
  border: 6px solid var(--primaryColor);
  border-radius: 50%;
  width: 25px;
  height: 25px;
}
.influencer-log-word {
  color: var(--primaryColor);
  font-size: 18px;
  font-weight: 500;
}
.influencer-log-name {
  color: var(--primaryColor);
  font-size: 20px;
  font-weight: 600;
}
.paginate-arrow {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 3rem;
}
.paginate-arrow img {
  cursor: pointer;
  width: 25px;
}
.paginate-word {
  padding: 0 1rem;
}

.logs-filter {
  display: flex;
  align-items: center;
  gap: 8px;
  border: 1px solid #b5b5b5;
  padding: 6px 16px 6px 16px;
  font-size: 16px;
  cursor: pointer;
  transition: 0.5s;
  position: relative;
}


.logs-filter .filter-drop-down {
  position: absolute;
  content: "";
  width: 14rem;
  border: 0.5px solid rgb(0 0 0 / 20%);
  background-color: white;
  margin: auto;
  top: 50px;
  left: -60px;
  z-index: 3;
  transition: all 2s ease-in-out;
  opacity: 0;
}
.logs-filter .filter-show {
  transition: 0.5s;
  opacity: 1;
}
.logs-filter .filter-hide {
  opacity: 0;
  transition: 0.5s;
  visibility: hidden;
}

.logs-filter .filter-drop-down ul {
  padding: 1rem;
  margin: 0;
}
.logs-filter .filter-drop-down ul div {
  color: #848484;
  font-size: 16px;
  font-weight: 400;
  border-bottom: 0.5px solid rgb(0 0 0 / 20%);
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}
.logs-filter .filter-drop-down ul div li {
  text-align: center;
}
.logs-filter .filter-drop-down ul div:hover {
  color: var(--primaryColor);
}

.logs-filter .filter-drop-down ul div:last-of-type {
  border: none;
  margin: 0;
  padding: 0;
}
.no-visit{
  display: flex;
    justify-content: center;
    align-items: center;
    min-height: 45vh;
}
.no-visit h2 {
  font-size: 45px;
    font-weight: 800;
    line-height: 63px;
    color: var(--primaryColor);
}

@media only screen and (max-width: 992px) {
  .influencers-logs-parent {
    padding: 19rem 0 0 0;
  }
  .logs {
    margin-top: 1rem;
    padding: 48px 0px;
  }
  .influencers-logs-title h1 {
    font-size: 28px;
  }
  .influencers-logs-filter .influencers-filter-sec2 {
    font-size: 16px;
  }
  .influencer-log-word {
    font-size: 16px;
  }
  .influencer-log-name {
    font-size: 18px;
  }
}
@media only screen and (max-width: 768px) {
  .logs {
    padding: 48px 25px;
  }
  .influencers-logs-parent {
    padding: 10rem 0 0 0;
  }
  .influencers-logs-title h1 {
    font-size: 18px;
  }
  .influencers-logs-filter .influencers-filter-sec1 {
    font-size: 14px;
  }
  .influencers-logs-filter .influencers-filter-sec2 {
    font-size: 13px;
  }
  .border-logs {
    flex-wrap: wrap;
    justify-content: center;
  }
  .logs-head {
    flex-direction: column;
    align-items: center;
    gap: 0;
  }
  .circle-logs {
    top: 50%;
  }
  .logs-time p {
    font-size: 14px;
  }
  .no-visit h2 {
    font-size: 23px;
  }
  .logs-filter{
    font-size: 10px;
  }
  .influencers-logs-title
  {
    width: 60%;
  }
}
