.smou-login-page {
  background-image: url(../../smou-imgs/smou-login-bg.webp);
  background-position: top;
  background-size: cover;
  height: 100vh;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

.side-left {
  grid-column: 2/3;
  display: flex;
  align-items: center;
  justify-content: center;
}
.side-left .login-content {
  color: #fff;
  background: rgba(255, 255, 255, 50%);
  border-radius: 32px;
  padding: 4rem;
  background-image: url(../../smou-imgs/smoue-login-back.webp);
  background-size: cover;
  background-position: center;
  height: 88vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.side-left .login-content h4 {
  margin: 2rem 0;
  font-size: 35px;
  font-weight: 600;
  line-height: 49.35px;
}
.side-left .login-content .smou-login-info {
  font-size: var(--main-font-size);
  font-size: 20px;
  font-weight: 300;
  line-height: 50px;
}
@media only screen and (max-width: 992px) {
  .side-left{
    display: none;
  }
  .smou-login-page{
    grid-template-columns: 1fr;
    align-items: center;
  }
}