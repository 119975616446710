.brand-register-parent {
  padding: 5rem 12rem 11rem;
  background-repeat: no-repeat;
  background-size: cover;
}
.smoue-brand-register-parent {
  padding: 11rem 12rem 11rem;
  background-image: url(../../assets/images/smou-register-back.webp);
}
.trade-mark-register {
  background-color: white;
  padding: 6rem 5rem 4rem;
  border-radius: 25px;
  margin-top: -5rem;
  z-index: 4;
  position: relative;
}
.register-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 7rem;
  width: 45%;
  margin: auto;
}
.register-logo .logo {
  width: 100%;
}
hr {
  width: 90%;
  margin: auto;
  color: black;
}
.trade-mark-register .register-title {
  display: grid;
  justify-content: center;
  text-align: center;
  gap: 1rem;
  margin-top: -4rem;
  margin-bottom: 1rem;
}
.register-title h3 {
  font-size: 31px;
  font-weight: 700;
  color: var(--primaryColor);
}
.trade-mark-register .register-title h6 {
  font-size: 23px;
  font-weight: 300;
  width: 80%;
  margin: auto;
  line-height: 48px;
  color: var(--primaryColor);
}
.trade-mark-register .trade-mark-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 7rem;
  margin-top: 2rem;
  margin-bottom: 3rem;
}
.trade-mark-register .trade-mark-form .form-sec {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.trade-mark-register .trade-mark-form .form-sec label {
  font-size: 20px;
  font-weight: 500;
  color: var(--primaryColor);
}
.trade-mark-register .trade-mark-form .form-sec input,
.trade-mark-register .trade-mark-form .form-sec .form-select {
  padding: 0.7rem;
  border: 1px solid #cac8c8;
  border-radius: 4px;
}
.tak-contact-info-form .PhoneInputInput{
  border: 1px solid rgb(255, 5, 147) !important;
  background-color: rgba(31, 31, 31, 1);
  color:white
}
.trans-contact-info-form .PhoneInputInput{
  border: 1px solid #02C6C6 !important;
  background-color: rgba(31, 31, 31, 1);
  color:white
}
.shark-contact-info-form .PhoneInputInput{
  border: 1px solid rgb(181, 89, 232) !important;
  background-color: rgba(31, 31, 31);
  color:white
}

.athar-contact-info-form .PhoneInputInput{
  border: 1px solid #F15A3A !important;
  background-color: transparent;
  color:white
}
.fadaa-contact-info-form .PhoneInputInput{
  border: 1px solid rgb(116, 68, 189) !important;
  background-color: transparent;
  color:white
}

.trade-mark-register .trade-mark-form .form-sec input, .trade-mark-register .trade-mark-form .form-sec .form-select
.trade-mark-register .trade-mark-form .form-sec input.PhoneInputInput {
  width: 100%;
}
.trade-mark-register .trade-mark-form .form-sec input::placeholder,
.trade-mark-register .trade-mark-form .form-sec .form-select::placeholder {
  font-size: 16px;
  font-weight: 300;
  color: #ababab;
}
.trade-mark-register input[type="tel"] ::placeholder {
  direction: rtl;
}
.trade-mark-register .button-register {
  background-color: var(--primaryColor);
  color: white;
  width: 40%;
  padding: 0.3rem;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  border-radius: 4px;
  border: none;
}
.trade-mark-register .PhoneInputCountrySelect {
  padding: 0.3rem;
  width: 10vw;
  border: 1px solid #cac8c8;
  font-size: 16px;
  font-weight: 300;
  color: #ababab;
}
.tak-contact-info-form .PhoneInputCountrySelect {
  color: white;
  background-color: rgba(31, 31, 31, 1);
  border: 1px solid rgb(255, 5, 147);
}
.trans-contact-info-form .PhoneInputCountrySelect {
  color: white;
  background-color: rgba(31, 31, 31, 1);
  border: 1px solid #02C6C6;
}
.shark-contact-info-form .PhoneInputCountrySelect{
  border: 1px solid rgb(181, 89, 232) ;
  background-color: rgba(31, 31, 31);
  color:white
}
.athar-contact-info-form .PhoneInputCountrySelect{
  border: 1px solid #F15A3A ;
  background-color: transparent;
  color:#ada3a3;
}
.fadaa-contact-info-form .PhoneInputCountrySelect{
  border: 1px solid rgb(116, 68, 189) ;
  background-color: black;
  color:white;
}
.tak-border{
  background: rgb(31, 31, 31);
  border-width: 0 1px 1px 1px; 
  border-style: none solid solid solid; 
  border-color: transparent #B1B1B1 #B1B1B1 #B1B1B1; 
  border-bottom-left-radius: 25px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}
.athar-border{
  background: #F1F1F1;
  border-width: 0 1px 1px 1px; 
  border-style: none solid solid solid; 
  border-color: transparent #B1B1B1 #B1B1B1 #B1B1B1; 
  border-radius: 0;
}
.PhoneInputCountryIcon {
  display: none;
}
.PhoneInput {
  display: flex;
  align-items: center;
}
.toogle-dir {
  direction: ltr;
}
.css-13cymwt-control,
.css-1nmdiq5-menu {
  padding: 0.3rem;
  color: black;
}
.spanhint {
  color: red;
}

.hintMsg {
  margin: auto;
  text-align: center;
  padding: 0.5rem;
  font-size: 20px;
  width: fit-content;
}
.form-right {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.form-left {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.brand-register-logo {
  position: absolute;
  top: -26px;
  right: 25px;
  width: 9%;
}
.brand-register-logo .logo {
  width: 100%;
}
@media only screen and (max-width: 1100px) {
  .trade-mark-register .trade-mark-form {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  .brand-register-parent {
    padding: 0;
  }
  .brand-register-logo {
    top: 2px;
  }
  .trade-mark-register .button-register {
    width: 100%;
  }
}
@media only screen and (max-width: 992px) {
  .register-title h3 {
    font-size: 20px;
    font-weight: 700;
  }
  .register-title h6 {
    font-size: 18px;
  }
  .influencer-register .circles .titles span {
    font-size: 16px;
  }
  .register-logo {
    height: 5rem;
  }
  .trade-mark-register .trade-mark-form .form-sec label {
    font-size: 18px;
  }
  .trade-mark-register .trade-mark-form .form-sec input::placeholder,
  .trade-mark-register .trade-mark-form .form-sec .form-select::placeholder {
    font-size: 12px;
  }
  .trade-mark-register .trade-mark-form {
    gap: 1rem;
    grid-template-columns: 1fr;
  }
  .hintMsg {
    font-size: 12px;
  }
  .brand-register-parent {
    background-color: var(--primaryColor);
    height: 140vh;
  }
  .trade-mark-parent .trade-mark-subparent .trade-mark-register {
    height: 116vh;
  }
  .trade-mark-parent {
    height: 122vh;
  }
  .trade-mark-parent .trade-mark-subparent {
    height: 119vh;
  }
  .brand-register {
    padding-top: 8rem;
  }
  .trade-mark-register .PhoneInputCountrySelect {
    padding: 0.32rem;
    width: 14vw;
  }
  .form-right,
  .form-left {
    gap: 1rem;
  }

  .trade-mark-register {
    padding: 6rem 2rem 4rem;
  }
  .trade-mark-register .register-title h6 {
    width: 100%;
    font-size: 18px;
  }
  .smoue-brand-register-parent {
    padding: 7rem 0rem 2rem 0rem;
  }
}
@media only screen and (max-width: 768px) {
  .brand-register {
    padding: 1.7rem 0;
  }
  .brand-register-logo {
    position: absolute;
    top: -33px;
    right: 19px;
  }
  .trade-mark-parent .register-title h6 {
    font-size: 15px;
    width: 100%;
    line-height: 31.68px;
  }

  .trade-mark-parent .trade-mark-subparent .trade-mark-register {
    padding: 7rem 2rem 0;
  }
}

@media only screen and (max-width: 400px) {
  .brand-register-logo {
    width: 17%;
  }
}
