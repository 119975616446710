@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@100;200;300;400;500;600;700&display=swap');

  /* @import url('https://fonts.googleapis.com/css2?family=Lemonada:wght@300;400;500;600&family=Noto+Kufi+Arabic:wght@100;200;300;400;500&family=Noto+Naskh+Arabic&family=Noto+Sans+Cypro+Minoan&family=Nunito:ital,wght@0,200;0,700;0,800;1,200;1,600&display=swap'); */

* {
  font-family: "Noto Sans Arabic", sans-serif;
  
  /* font-family: 'Noto Kufi Arabic', sans-serif;
  font-family: 'Noto Naskh Arabic', serif;
  font-family: 'Noto Sans Cypro Minoan', sans-serif;
  font-family: 'Nunito', sans-serif;
  font-family: 'Lemonada', cursive; */
  direction: rtl;
  margin: 0;
  padding: 0;
  list-style: none;
  scroll-behavior: smooth;
}
:root {
  --primaryColor: #022444;
  --secondryColor: #ffff;
  --btnColor: #21cade;
  --dotColor: #76c5f5;
  --lineColor: #ffffff;
  --cardColor: #daf1ff;
  --borderColor: #77c9ff;
  --activeCardColor: #022444;
  --activePagenation: 022444;
  --boxShadow: 0px -1px 15px 0px rgba(153, 216, 255, 0.5);
  /* 0px -1px 15px 0px #e2e4e5 */
}
a {
  text-decoration: none;
}

@keyframes bouncing {
  0%,
  10%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40%,
  60% {
    transform: translateY(-15px);
  }
}
